import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import markdownIt from "markdown-it";
import { markdownItTable } from "markdown-it-table";
// @ts-ignore no types available
import markdownItFootnote from "markdown-it-footnote";
import sanitizeHtml from "sanitize-html";
import { unescape, truncate } from "lodash";
// @ts-ignore no types available
import qs from "./QueryStringUtils";
// @ts-ignore no types available
import { capitalize } from "./util";
import ModeWLE, { DropdownType, IFrameOptsType } from "./mode/ModeWLE";

type DivWithContent = {
  content: string;
} & React.InputHTMLAttributes<HTMLDivElement>;

// Keep rendering options in sync with bookface/lib/markdown_renderer.rb
const MarkdownIt = markdownIt("commonmark", {
  linkify: true,
  html: false,
})
  .enable("linkify")
  .enable("strikethrough");
MarkdownIt.use(markdownItTable);

export const renderMarkdown = (src: string, env?: any): string => MarkdownIt.render(src, env);
export default renderMarkdown;

export const MarkdownComponent = ({ content, ...rest }: DivWithContent) => (
  <div
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: renderMarkdown(content || ""),
    }}
  />
);

export const parameterize = (phrase: string) =>
  truncate(
    phrase
      .toLowerCase()
      .replace(/<[^>]*>/g, "") // Remove any html tags
      .replace(/[^0-9a-z]/g, " ") // Remove non-alphanumerics
      .replace(/^\s+|\s+$/g, "") // Remove leading and trailing spaces
      .replace(/[-\s]+/g, "-"), // Convert spaces to hyphens
    { length: 100, separator: "-", omission: "" } // Word-aligned truncation
  );

const addTableOfContents = (html: string, includeTocBox: boolean = true) => {
  let content = html;
  let first: string | null = null;
  const toc: { id: string; title: string; depth: number }[] = [];
  const entries: string[] = [];
  // get the entries for the table of contents
  const headingRegex = /<h(2|3)>(.*)<\/h(2|3)>/g;
  // eslint-disable-next-line consistent-return
  [...content.matchAll(headingRegex)].forEach((m) => {
    const [matched, headingType, headingText] = m;
    // only process non-empty headers
    if (/\w/.test(headingText)) {
      // add an id (could be name) attribute to the heading to make it linkable
      const id = parameterize(headingText);
      const linkable = `<h${headingType} id="${id}">${headingText}</h${headingType}>`;
      // store the place where we'll inject the box
      if (!first) first = linkable;
      content = content.replace(matched, linkable);
      // add this entry to the table of contents
      toc.push({
        id,
        title: unescape(sanitizeHtml(headingText, { allowedTags: [] })),
        depth: Number(headingType),
      });
      // add an entry to the list of toc items
      const entry = `
      <li>
        <a href="#${id}">${headingText}</a>
      </li>
    `;
      entries.push(headingType === "3" ? `<ul>${entry}</ul>` : entry);
    }
  });
  const box = `
  <div class="contents">
    <details open="1">
      <summary>
        <b>Contents</b>
      </summary>
      <ul>${entries.join("")}</ul>
    </details>
  </div>
`;
  if (entries.length >= 3 && includeTocBox && first)
    content = content.replace(first, `${box}${first}`);
  return { content, toc };
};

const expandCustomComponentEmbeds = (content: string): { [key: string]: [Function, object] } => {
  const idToComponents: { [key: string]: [Function, object] } = {};
  (
    [
      [
        /*
      https://app.mode.com/[ORG_NAME]/reports/[REPORT_TOKEN]/embed
      for reports with params, add configuration via query parameters
      e.g. https://app.mode.com/[ORG_NAME]/reports/[REPORT_TOKEN]/embed?round.default=1&round.choice.Series%20A=1&round.choice.Series%20B=2
      Breaking down the above example, the dashboard has a single param called "round".
      This param has two choices, Series A (1) and Series B (2).
      The default value is Series A.
         param name: round
         default value: 1 (round.default=1)
         choices:
           Series A: 1 (round.choice.Series%20A=1)
           Series B: 2 (round.choice.Series%20B=2)
      The dropdown label defaults to the capitalized version of the param name, in this case, Round.
      To give the dropdown a different name: round.label=Another%20name
      To enable multi-select: round.multi=true
      Width, height, and border of the iframe can be given like: width=100% or height=600px or border=0
      Param dropdowns default to horizontal with 2rem margin-right.
      To change the margin on the params: params_margin=5rem
      To layout the dropdowns vertically: params_layout=vertical
      */
        /<https:\/\/app\.mode\.com\/ycombinator\/reports\/(?<id>[a-z0-9-_]+)\/embed(\?(?<qs>.+))?>/gi,
        (id, queryString) => {
          const ddConfig: (DropdownType | null | Partial<DropdownType>)[] = [];
          const iframeOpts: IFrameOptsType = { width: "100%", height: "450px" };
          let tmp: DropdownType | null | Partial<DropdownType> = null;
          let oldKey: string | null = null;
          let paramsLayout = "horizontal";
          let paramsMargin = "2rem";
          Object.entries(
            qs.parse(queryString, { sort: false }) as { [key: string]: string }
          ).forEach(([k, v]) => {
            if (k == "width") {
              iframeOpts.width = v;
              return;
            }
            if (k == "height") {
              iframeOpts.height = v;
              return;
            }
            if (k == "border") {
              iframeOpts.border = v;
              return;
            }
            if (k == "params_layout") {
              paramsLayout = v;
              return;
            }
            if (k == "params_margin") {
              paramsMargin = v;
              return;
            }
            const [key, ...rest] = k.split(".");
            if (key !== oldKey) {
              if (tmp) {
                if (tmp?.multi && tmp?.default && !Array.isArray(tmp?.default)) {
                  // @ts-ignore
                  tmp.default = [tmp.default];
                }
                ddConfig.push({ ...tmp });
              }
              tmp = { key, label: capitalize(key), multi: false };
              oldKey = key;
            }
            const [attr, ...vals] = rest;
            if (attr === "choice" && tmp) {
              if (!tmp.choices) {
                tmp.choices = [];
              }
              tmp.choices.push({
                label: vals[0],
                value: v,
              });
            } else {
              // @ts-ignore
              tmp[attr] = v;
            }
          });
          if (ddConfig.filter((c) => c?.key === oldKey).length === 0 && oldKey && tmp) {
            // @ts-ignore
            if (tmp?.multi && tmp?.default && !Array.isArray(tmp?.default)) {
              // @ts-ignore
              tmp.default = [tmp.default];
            }
            ddConfig.push(tmp);
          }
          return [
            ModeWLE,
            {
              reportId: id,
              dropdownConfigs: ddConfig,
              dropdownLayout: paramsLayout,
              dropdownMarginRight: paramsMargin,
              iframeOpts,
            },
          ];
        },
      ],
    ] as [RegExp, (id: string, queryString?: string) => [Function, object]][]
  ).forEach(([re, template]) => {
    let result: RegExpExecArray | null;
    // eslint-disable-next-line no-cond-assign
    while ((result = re.exec(content)) !== null) {
      const id = result?.groups?.id;
      if (id) {
        idToComponents[id] = template(id, result?.groups?.qs);
      }
    }
  });
  return idToComponents;
};

export const renderTella = (id: string) =>
  `<p class="embed-container tella">
    <iframe width="600" height="450" src="https://tella.tv/video/${id}/embed" allowfullscreen></iframe>
  </p>`;

const expandEmbeds = (content: string) => {
  let expandedContent = content;
  // build up a map from string index to the string to be displayed at that index
  // use a Map because Objects suffer from type conversions
  const indexToStrings = new Map<number, string[]>();
  (
    [
      [
        // https://app.mode.com/[ORG_NAME]/reports/[REPORT_TOKEN]/embed
        /https:\/\/app\.mode\.com\/ycombinator\/reports\/(?<id>[a-z0-9-_]+)\/embed/gi,
        (id) => `<div class="mode-wle" id="custom-${id}"></div>`,
      ],
      [
        /\(https:\/\/drive\.google\.com\/file\/d\/(?<id>[a-z0-9-_]+)\/[^)]*\)/gi,
        (id) =>
          `<p class="embed-container google-drive">
          <iframe width="600" height="450" src="https://drive.google.com/file/d/${id}/preview" allow="autoplay"></iframe>
        </p>`,
      ],
      [
        /(?:https??:\/\/)?(?:www\.)?(?:(?:youtube\.com\/watch\?v=)|(?:youtu.be\/))(?<id>[a-z0-9\-_?=]+)/gi,
        (id) =>
          `<p class="embed-container youtube">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/${id}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </p>`,
      ],
      [/https:\/\/www\.tella\.tv\/video\/(?<id>[a-z0-9\-_]+)/gi, renderTella],
      [/https:\/\/tella\.video\/(?<id>[a-z0-9\-_]+)/gi, renderTella],
      [
        /https:\/\/www\.loom\.com\/share\/(?<id>[a-z0-9_-]+)(?:\?sid=[a-z0-9_-]+)?/gi,
        (id) => `<p class="embed-container loom">
          <iframe width="600" height="450" src="https://www.loom.com/embed/${id}" allowfullscreen></iframe>
        </p>`,
      ],
    ] as [RegExp, (id: string | undefined) => string][]
  ).forEach(([re, template]) => {
    let result: RegExpExecArray | null;
    // eslint-disable-next-line no-cond-assign
    while ((result = re.exec(expandedContent)) !== null) {
      const idx = `${expandedContent}\n`.indexOf("\n", result.index);
      const string = template(result?.groups?.id);
      const extant = indexToStrings.get(idx) || [];
      indexToStrings.set(idx, extant.concat(string));
    }
  });

  // iterate over the indices in decreasing order
  [...indexToStrings.entries()]
    .sort((a, b) => b[0] - a[0])
    .forEach(([index, strings]) => {
      if (strings[0]?.indexOf("iframe") === -1) {
        // eslint-disable-next-line no-param-reassign
        index = expandedContent.substring(0, index).lastIndexOf("<http");
      }
      expandedContent =
        expandedContent.substring(0, index) + strings[0] + expandedContent.substring(index);
    });

  return expandedContent;
};

// For KB articles only, allow html and footnotes
export const ArticleMarkdownIt = markdownIt("commonmark", {
  linkify: true,
  html: true,
})
  .enable("linkify")
  .enable("strikethrough");
ArticleMarkdownIt.use(markdownItTable);
ArticleMarkdownIt.use(markdownItFootnote);

const sanitizeOptions = {
  allowedTags: [
    "a",
    "b",
    "br",
    "blockquote",
    "code",
    "div",
    "del",
    "em",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "hr",
    "iframe",
    "img",
    "li",
    "ol",
    "p",
    "pre",
    "s",
    "span",
    "sub",
    "sup",
    "strong",
    "strike",
    "table",
    "tbody",
    "td",
    "th",
    "thead",
    "tr",
    "u",
    "ul",
  ],
  allowedAttributes: {
    "*": ["id"],
    p: [
      {
        name: "class",
        multiple: true,
        values: ["embed-container", "youtube", "google-drive"],
      },
    ],
    div: [
      {
        name: "class",
        multiple: true,
        values: ["embed-container", "mode-wle"],
      },
    ],
    a: ["href", "name", "target"],
    iframe: [
      "src",
      "height",
      "width",
      "style",
      { name: "frameborder", multiple: false, values: ["no", "0"] },
      { name: "allowfullscreen", multiple: false, values: ["1"] },
    ],
    img: ["src"],
  },
  allowedIframeHostnames: [
    "www.youtube.com",
    "w.soundcloud.com",
    "docs.google.com",
    "drive.google.com",
    "tella.video",
    "tella.tv",
    "codepen.io",
    "www.loom.com",
    "loom.com",
    "bookface-embed.ycombinator.com",
    "ycshorts.portal.massive.app",
  ],
};

const processArticleContent = (content: string, includeTocBox: boolean) => {
  let c = content;
  c = expandEmbeds(c);
  c = ArticleMarkdownIt.render(c);
  c = sanitizeHtml(c, sanitizeOptions);
  return addTableOfContents(c, includeTocBox);
};

export const articleRenderMarkdown = (content: string, includeTocBox: boolean) => {
  const article = processArticleContent(content, includeTocBox);
  return article.content;
};

export const markdownTocList = (content: string) => {
  const article = processArticleContent(content, false);
  return article.toc;
};

export const ArticleMarkdownComponent = ({
  content,
  includeTocBox = true,
  ...rest
}: {
  includeTocBox?: boolean;
} & DivWithContent) => {
  const [params, setParams] = useState<{ [key: string]: { [key: string]: string | string[] } }>({});
  const [customComponents, setCustomComponents] = useState<{ [key: string]: [Function, object] }>(
    {}
  );
  // Insert a marker at the start of the matched text
  const renderedMarkdown = useMemo(() => {
    const articleMarkdown = articleRenderMarkdown(content, includeTocBox);
    setCustomComponents(expandCustomComponentEmbeds(content));

    return articleMarkdown;
  }, [content]);

  // Initialize any custom components
  useEffect(() => {
    Object.entries(customComponents).forEach(([id, obj]) => {
      const [component, args] = obj;
      ReactDOM.render(
        component({
          ...args,
          params: params[id] || {},
          setParams: (v: {}) => setParams({ ...params, [id]: v }),
        }),
        document.querySelector(`#custom-${id}`)
      );
    });
  });

  return (
    <div
      {...rest}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: renderedMarkdown,
      }}
    />
  );
};

export const stripMarkdown = (content: string, allowedTags?: string[]) =>
  sanitizeHtml(ArticleMarkdownIt.render(content), {
    allowedTags: allowedTags || ["span"],
    allowedAttributes: {
      span: ["class"],
    },
  }).trim();

export const contentWordCount = (content: string) => stripMarkdown(content).split(/\s+/).length;

// Same as above, but with images disallowed
const TextMarkdownIt = markdownIt("commonmark", {
  linkify: true,
  html: false,
})
  .enable("linkify")
  .disable("image");

export const textRenderMarkdown = TextMarkdownIt.render.bind(TextMarkdownIt);

export const TextMarkdownComponent = ({ content, ...rest }: DivWithContent) => (
  <div
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: textRenderMarkdown(content),
    }}
  />
);

// Same as above, but open links in new window
const TextMarkdownItLinksInNewWindow = markdownIt("commonmark", {
  linkify: true,
  html: false,
})
  .enable("linkify")
  .disable("image");

// https://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md#renderer
// Should probably actually use https://github.com/crookedneighbor/markdown-it-link-attributes
// Remember old renderer, if overridden, or proxy to default renderer
const defaultRender =
  TextMarkdownItLinksInNewWindow.renderer.rules.link_open ||
  ((tokens, idx, options, _env, self) => self.renderToken(tokens, idx, options));

TextMarkdownItLinksInNewWindow.renderer.rules.link_open = (tokens, idx, options, env, self) => {
  // If you are sure other plugins can't add `target` - drop check below
  const aIndex = tokens[idx].attrIndex("target");

  if (aIndex < 0) {
    tokens[idx].attrPush(["target", "_blank"]); // add new attribute
  } else {
    const { attrs } = tokens[idx];
    if (attrs !== null) {
      attrs[aIndex][1] = "_blank"; // replace value of existing attr
    }
  }

  // pass token to default renderer.
  return defaultRender(tokens, idx, options, env, self);
};

const textRenderMarkdownLinksInNewWindow = TextMarkdownItLinksInNewWindow.render.bind(
  TextMarkdownItLinksInNewWindow
);

export const TextMarkdownLinksInNewWindowComponent = ({ content, ...rest }: DivWithContent) => (
  <div
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: textRenderMarkdownLinksInNewWindow(content),
    }}
  />
);

// Needed until internal is past React 16.3 or so
export const RefMarkdownComponent = ({
  content,
  childRef,
  showImages = true,
  ...rest
}: {
  childRef: React.LegacyRef<HTMLDivElement>;
  showImages: boolean;
} & DivWithContent) => (
  <div
    ref={childRef}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: showImages ? renderMarkdown(content || "") : textRenderMarkdown(content || ""),
    }}
  />
);
