import React, { CSSProperties } from "react";
import debounce from "lodash/debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "./Dropdown";

declare global {
  interface Window {
    MODE_EMBED_LOADED: boolean;
  }
}

export type DropdownType = {
  label: string;
  key: string;
  default: string;
  choices: {
    label: string;
    value: string;
  }[];
  multi: boolean;
};

export type IFrameOptsType = {
  width: string;
  height: string;
  border?: string;
};

export default function ModeWLE({
  reportId,
  dropdownConfigs,
  iframeOpts,
  params,
  setParams,
  dropdownLayout = "horizontal",
  dropdownMarginRight = "2rem",
}: {
  reportId: string;
  dropdownConfigs: DropdownType[];
  iframeOpts: IFrameOptsType;
  params: { [key: string]: string };
  setParams: Function;
  dropdownLayout: string;
  dropdownMarginRight: string;
}): React.ReactElement {
  let { width = "100%", height = "450px", border = "1px solid #ddd" } = iframeOpts;
  let autoHeight = false;
  if (height === "auto") {
    autoHeight = true;
    height = "100vh";
  }
  const dropdownStyle: CSSProperties = {
    display: "inline-block",
    marginRight: dropdownMarginRight,
  };
  if (dropdownLayout !== "horizontal") {
    dropdownStyle.display = "table";
  }
  const borderWidth = Number(/(?<num>[0-9]+)px/gi.exec(border)?.groups?.num || "0");
  const defaultParams: { [key: string]: string } = { ...params };
  dropdownConfigs.forEach((config) => {
    defaultParams[config.key] = config.default;
  });
  if (Object.keys(params).length === 0 && Object.keys(defaultParams).length > 0) {
    setParams(defaultParams);
  }
  const updateParams = (key: string, value: string | number | string[] | number[]) => {
    let val = value;
    if (Array.isArray(val)) {
      // @ts-ignore
      val = val.map((v: string | number) => String(v));
    } else {
      val = String(val);
    }
    setParams({
      ...params,
      [key]: val,
    });
  };
  let src = `${window.location.origin.replace(
    "bookface",
    "bookface-embed"
  )}/mode_embeds/${reportId}?params=${JSON.stringify(params)}`;
  const style: CSSProperties = { width, height, border };
  const id = `report-${reportId}`;
  const paramsId = `${id}-params`;
  if (autoHeight) {
    src = `${src}&resize=true`;
    style.pointerEvents = "none";
    const onmessage = (event: MessageEvent) => {
      if (event.data && event.data.id === id) {
        const el = document.getElementById(id);
        const paramsEl = document.getElementById(paramsId);
        if (el) {
          const paramsScrollHeight = paramsEl?.scrollHeight || 0;
          el.style.height = `${event.data.height + borderWidth + paramsScrollHeight}px`;
          el.style.pointerEvents = "all";
        }
      }
    };
    window.addEventListener("message", onmessage, false);
    // Propagate resize requests down to the embed-content iframe
    window.addEventListener(
      "resize",
      debounce(() => {
        const frames: NodeListOf<HTMLIFrameElement> =
          document.querySelectorAll("iframe[data-report]");
        frames.forEach((frame) => {
          frame.contentWindow?.postMessage(
            {
              name: "getNewSize",
              current: {
                height: frame.offsetHeight,
                width: frame.offsetWidth,
              },
            },
            frame.src
          );
        });
      }, 250)
    );
  }

  // @ts-ignore
  return (
    <>
      <div id={paramsId}>
        {dropdownConfigs.map(({ label, choices, key, multi }) => (
          <div key={key} style={dropdownStyle}>
            <label>{label}</label>
            <Dropdown
              choices={choices}
              selected={params[key] || (multi ? [] : "")}
              onSelect={(selection: any) => updateParams(key, selection)}
              multi={multi}
            />
          </div>
        ))}
      </div>
      <div>
        <div
          id="embed-loading"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            fontSize: "3em",
          }}
        >
          <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
        </div>

        <iframe
          id={id}
          onLoad={() => {
            if (document) {
              // @ts-ignore
              setTimeout(() => {
                const element = document.getElementById("embed-loading");
                if (element) {
                  element.style.display = "none";
                }
              }, 1500);
            }
          }}
          title={`Mode dashboard ${reportId}`}
          style={style}
          src={src}
          allow="autoplay"
          data-report={reportId}
        />
      </div>
    </>
  );
}
