import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, StylesProvider, createGenerateClassName } from "@material-ui/core";
import { Provider } from "./graphql";
import theme from "./themes/theme";

export default function registerTopLevelComponents(componentsToRegister: {
  [name: string]: React.ComponentType;
}) {
  const components = componentsToRegister;

  document.addEventListener("DOMContentLoaded", () => {
    if (window.REACT_COMPONENTS) {
      window.REACT_COMPONENTS.forEach(({ divId, component, props, graphQL }) => {
        const reactComponent = components[component];
        if (reactComponent) {
          const generateClassName = createGenerateClassName({
            seed: component,
          });

          let element = (
            <StylesProvider generateClassName={generateClassName}>
              <ThemeProvider theme={theme}>
                {React.createElement(reactComponent, props)}
              </ThemeProvider>
            </StylesProvider>
          );

          if (graphQL) {
            element = <Provider>{element}</Provider>;
          }

          ReactDOM.render(element, document.getElementById(divId));
        }
      });
    }
  });
}
