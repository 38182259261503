export default {
  switchBase: {
    "&$checked": {
      color: "white",
    },
  },
  colorSecondary: {
    "&$checked": {
      color: "#fff",
    },
  },
  track: {
    "$checked$checked + &": {
      backgroundColor: "#fb651e",
      opacity: 1,
    },
  },
};
