import React from "react";

import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const httpLink = createHttpLink({
  uri: "/graphql",
  fetchOptions: { credentials: "same-origin" },
});

const authLink = setContext((_, { headers }) => {
  // @ts-ignore
  const csrfToken = document.querySelector("meta[name=csrf-token]")?.content;
  // return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      "X-CSRF-Token": csrfToken,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );

  if (networkError) console.error(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      CofounderProfile: {
        keyFields: ["slug"],
      },
      CurrentUser: {
        keyFields: ["slug"],
      },
      User: {
        keyFields: ["slug"],
      },
      Company: {
        keyFields: ["slug"],
      },
      CofounderAdminProfile: {
        keyFields: ["slug"],
      },
    },
  }),
});

export const Provider: React.FC = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);
