import { useEffect } from "react";
import { datetime } from "rrule";
import { dateInTimeZone } from "rrule/dist/esm/dateutil";
import { defaults as mandeDefaults } from "mande";
import { defaults as authorizedFetchDefaults } from "./authorizedFetch";

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function stopPropagation(e) {
  if (e) {
    e.stopPropagation();
  }
}

export function stopPropagationAndPreventDefault(e) {
  if (e) {
    e.stopPropagation();
    e.preventDefault();
  }
}

export function alwaysArray(maybeArray) {
  if (Array.isArray(maybeArray)) {
    return maybeArray;
  }
  if (maybeArray === undefined || maybeArray === null) {
    return [];
  }
  return [maybeArray];
}

export function hashCode(str) {
  return str.split("").reduce((a, b) => {
    /* eslint-disable no-bitwise */
    const x = (a << 5) - a + b.charCodeAt(0);
    return x & x;
    /* eslint-enable no-bitwise */
  }, 0);
}

export function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.substr(1);
}

export function titleize(snake_case) {
  return snake_case
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.substr(1))
    .join(" ");
}

export const prettyJoin = ([...arr]) => arr.concat(arr.splice(-2, 2).join(" and ")).join(", ");

export const flatten = (a) => (Array.isArray(a) ? [].concat(...a.map(flatten)) : a);

export const pluralize = (count, singular = "", plural = "s") =>
  `${count !== 1 ? plural : singular}`;

export const scrollToTop = () => {
  const behavior = navigator.userAgent.search("Firefox") >= 0 ? "auto" : "smooth";

  window.scroll({
    top: 0,
    behavior,
  });
};

export const PAGE_SIZE = 20;

export const isMobile = () => {
  const agent = navigator.userAgent.toLowerCase();
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(agent);
};

export const convertFromUTCToTimezone = (date, tz) => {
  const time = date.getTime();
  const invDate = new Date(date.toLocaleString("en-US", { timeZone: tz }));
  const diff = time - invDate.getTime();
  return new Date(time + diff);
};

export const convertFromTimezoneToUTC = (date, tz) => {
  const time = date.getTime();
  const invDate = new Date(date.toLocaleString("en-US", { timeZone: tz }));
  const diff = time - invDate.getTime();
  return new Date(time - diff);
};

export const stringToDatetime = (str, tz = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
  const startDate = str.slice(0, 10).split("-");
  const startTime = str.slice(11, 19).split(":");
  return dateInTimeZone(
    datetime(startDate[0], startDate[1], startDate[2], startTime[0], startTime[1], startTime[2]),
    tz
  );
};

export const useMagicLink = () => {
  useEffect(() => {
    const magicUuid = new URLSearchParams(window.location.search).get("magic_uuid");
    if (magicUuid) {
      mandeDefaults.headers["X-Magic-UUID"] = magicUuid;
      authorizedFetchDefaults.headers["X-Magic-UUID"] = magicUuid;
    }
  }, []);
};

export const slugify = (str) =>
  str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-|-$/g, "");
