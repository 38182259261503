import { createTheme } from "@material-ui/core";
import MuiBackdrop from "./backdropTheme";
import MuiCheckbox from "./checkboxTheme";
import MuiRadio from "./radioTheme";
import MuiSwitch from "./switchTheme";

const theme = createTheme({
  overrides: {
    MuiBackdrop,
    MuiCheckbox,
    MuiRadio,
    MuiSwitch,
  },
});

export default theme;
