import queryString from "query-string";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

const parse = (searchStr, opts = {}) => queryString.parse(searchStr, opts);

const stringify = (searchObj) => queryString.stringify(searchObj);

// to cleanup/remove things like zero-length arrays that would be excluded from search strings anyway
export function canonicalize(searchObj) {
  return parse(stringify(pickBy(searchObj, identity)));
}

const updateSearch = (loc, data) => {
  let newSearch = {
    ...parse(loc.search),
    ...data,
  };

  // filter out nulls so we can unset qs params
  newSearch = pickBy(newSearch, identity);

  return {
    pathname: loc.pathname,
    search: stringify(newSearch),
    hash: loc.hash,
  };
};

const popFromSearch = (loc, paramName) => {
  const search = parse(loc.search);
  const popped = search[paramName];
  if (popped) {
    delete search[paramName];
  }

  const location = {
    ...loc,
    search: stringify(search),
  };

  return {
    location,
    value: popped,
  };
};

const replaceParams = (params) => {
  const paramStr = stringify(pickBy(params));
  window.history.replaceState(
    {},
    "",
    paramStr.length > 0 ? `?${paramStr}` : location.href.split("?")[0]
  );
};

export default {
  canonicalize,
  parse,
  popFromSearch,
  replaceParams,
  stringify,
  updateSearch,
};
