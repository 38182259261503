import React, { useMemo, useState } from "react";
import "./Dropdown.scss";

export type ChoiceType = {
  label: string;
  value: string | number;
};

export default function Dropdown({
  choices,
  selected,
  onSelect,
  displayFunc = (c: ChoiceType) => c?.label,
  multi = false,
  style = {},
  multiCombinedIndices = [0],
  defaultChoiceIndex = 0,
  searchable = false,
  className = "mode-dropdown",
}: {
  choices: ChoiceType[];
  selected: any;
  onSelect: (s: any) => void;
  displayFunc?: (c: ChoiceType) => string;
  multi?: boolean;
  style?: React.CSSProperties;
  multiCombinedIndices?: number[];
  defaultChoiceIndex?: number;
  searchable?: boolean;
  className?: string;
}): React.ReactElement {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const filteredChoices = useMemo(
    () => choices.filter((c) => c.label?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1),
    [choices, searchTerm]
  );
  const displayFunction = (s: any) => {
    const isArray = Array.isArray(s);
    if (isArray) {
      const arr = s as Array<string | number>;
      if (multi && arr.length > 1) {
        return `${arr.length} selected`;
      }
      return displayFunc(filteredChoices.filter((c: ChoiceType) => c.value === arr[0])[0]);
    }
    return displayFunc(filteredChoices.filter((c: ChoiceType) => c.value === s)[0]);
  };
  const defaultChoice = useMemo(() => choices[defaultChoiceIndex], []);
  const multiCombinedVals = useMemo(
    () => choices.filter((c, idx) => multiCombinedIndices.indexOf(idx) !== -1).map((c) => c.value),
    []
  );

  const isDefaultSelected = useMemo(
    () =>
      multi && Array.isArray(selected)
        ? selected.indexOf(defaultChoice.value) !== -1
        : defaultChoice.value === selected,
    []
  );
  return (
    <div
      className={[className, !isDefaultSelected && "selected"].filter(Boolean).join(" ")}
      style={style}
    >
      <span>{searchTerm ? "Searching..." : displayFunction(selected)}</span>
      <ul style={{ overscrollBehavior: "contain" }}>
        {searchable && (
          <li>
            <input
              value={searchTerm}
              onChange={({ target }) => setSearchTerm(target.value)}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setSearchTerm("");
                }
              }}
              placeholder="Search..."
              style={{ margin: "0.5rem 1rem", border: 0 }}
            />
          </li>
        )}
        {filteredChoices.map((c, idx) => {
          const { label, value } = c;
          if (multi) {
            const selectedArr = (selected || []) as Array<string | number>;
            return (
              <li key={value} className={selectedArr.indexOf(value) !== -1 ? "selected" : ""}>
                <a
                  onClick={() => {
                    // These choices can't be combined with other selections
                    // since they already represent a combination of selections
                    if (multiCombinedIndices.indexOf(idx) !== -1) {
                      return onSelect([value]);
                    }
                    if (selectedArr.indexOf(value) !== -1) {
                      const vals = selectedArr.filter(
                        (s: string | number) => multiCombinedVals.indexOf(s) === -1 && s !== value
                      ) as Array<string | number>;
                      if (vals.length === 0) {
                        vals.push(defaultChoice.value);
                      }
                      return onSelect(vals);
                    }
                    return onSelect([
                      ...selectedArr.filter(
                        (s: string | number) => multiCombinedVals.indexOf(s) === -1
                      ),
                      value,
                    ]);
                  }}
                >
                  {displayFunction(value)}
                </a>
              </li>
            );
          }
          return value === selected ? (
            <li key={label} className="selected">
              {displayFunction(value)}
            </li>
          ) : (
            <li key={label}>
              <a onClick={() => onSelect(value)}>{displayFunction(value)}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
